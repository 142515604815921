<template>
  <div class="content content--home">
    <h1 class="title title--big title--theme title--indent">События</h1>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <AppCells position="start">
      <button @click="onOpenSidebar" class="link" :class="{ 'filtered': isFiltered }">
        <img src="@/assets/img/filter-icon.svg" alt="Фильтры">
        <span>Фильтры</span>
      </button>
    </AppCells>
    <div class="filters filters--indent filters--between">
      <div class="filters__block">
        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps1"
          type="radio"
          name="status"
          value=""
          v-model="status"
        >
        <label for="apps1" class="filters__item">Все</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps2"
          type="radio"
          name="status"
          value="3"
          v-model="status"
          checked
        >
        <label for="apps2" class="filters__item">Завершенные</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps3"
          type="radio"
          name="status"
          value="1"
          v-model="status"
        >
        <label for="apps3" class="filters__item">Новые</label>

        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps4"
          type="radio"
          name="status"
          value="2"
          v-model="status"
        >
        <label for="apps4" class="filters__item">В процессе</label>
        <input
          @change="getList(1, limitSelected.name, status)"
          id="apps5"
          type="radio"
          name="status"
          value="4"
          v-model="status"
        >
        <label for="apps5" class="filters__item">Просроченные задачи</label>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <v-client-table
        @row-click="onRowClick"
        :data="table.items"
        :columns="table.columns"
        :options="table.options"
        ref="tasksListTable"
        class="table-default table-default--dynamic table-default--minw"
      >
        <div slot="name" slot-scope="props" class="table-default__left">
          {{ props.row.name }}
        </div>

        <div slot="desc" slot-scope="props">
          <span v-html="props.row.desc"></span>
        </div>

        <div slot="workers" slot-scope="props">
          <span v-if="props.row.workers.executor.length" class="table-default__item">
            <span v-for="prop in props.row.workers.executor">
              <p class="table-default__title">Иполнитель: </p>
              <span>{{prop.full_name}}</span>
            </span>
          </span>

          <span v-if="props.row.workers.co_executor.length" class="table-default__item">
            <p class="table-default__title">Соисполнитель: </p>
            <span v-for="prop in props.row.workers.co_executor">
              {{prop.full_name}}
            </span>
          </span>

          <span v-if="props.row.workers.observer.length" class="table-default__item">
            <p class="table-default__title">Наблюдатель: </p>
            <span v-for="prop in props.row.workers.observer">
              {{prop.full_name}}
            </span>
          </span>
        </div>
      </v-client-table>
    </transition>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
    <AppSidebarRight
      title="Фильтры"
      :class="{'sidebar-manage--full': sidebar_open}"
      @close-sidebar="sidebar_open = false"
    >
      <form @submit.prevent>
        <AppFormGroup label="Участники">
          <AppSelect
            v-model="filters.member"
            :options="member_options"
            :reduce="status => status.id"
            :filterable="false"
            :searchable="true"
            label="id"
            placeholder="Выберите участника"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #no-options>Ничего не найдено</template>
            <template #option="option">
              <div class="select__item d-center">{{ option.full_name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.full_name }}</div>
            </template>
          </AppSelect>
        </AppFormGroup>
        <AppFormGroup label="Дата начала">
          <AppInput
            id="start_date"
            v-model="filters.start_date"
            v-mask="'99.99.9999'"
            placeholder="дд.мм.гггг"
            autocomplete="off"
          />
        </AppFormGroup>
        <AppFormGroup label="Дата окончания">
          <AppInput
            id="end_date"
            v-model="filters.end_date"
            v-mask="'99.99.9999'"
            placeholder="дд.мм.гггг"
            autocomplete="off"
          />
        </AppFormGroup>
        <AppFormGroup label="Статус задачи">
          <AppCheckbox
            v-model="filters.expired"
            id="4"
            name="input"
            label="Просроченная"
          />
        </AppFormGroup>
        <AppCells>
          <AppButton size="stretch" type="button" @click="applyFilters">Применить</AppButton>
        </AppCells>
        <AppCells v-if="isFiltered">
          <AppButton size="stretch" theme="transparent" type="button" @click="removeFilters">Сбросить</AppButton>
        </AppCells>
      </form>
    </AppSidebarRight>
  </div>
</template>

<script>
  import dropdown from 'vue-dropdowns';

  export default {
    name: 'EventList',
    components: {
      dropdown
    },
    data() {
      return {
        items: [
          {
            'title': 'Все',
            'type': 'all'
          },
          {
            'title': 'Завершенная',
            'type': 'completed'
          },
          {
            'title': 'Новая',
            'type': 'in-process'
          },
          {
            'title': 'В процессе',
            'type': 'profile-career'
          },
          {
            'title': 'Просроченные задачи',
            'type': 'overdue-tasks'
          },

        ],
        list: [],
        filter: 'all',
        table: {
          items: [],
          columns: ['name', 'desc', 'start_date', 'deadline', 'status_name', 'workers'],
          options: {
            headings: {
              name: 'Наименование',
              desc: 'Описание',
              start_date: 'Дата начала',
              deadline: 'Крайний срок',
              status_name: 'Статус задачи',
              workers: '',
            },
            pagination: {show: false},
            sortable: [],
            perPage: 20,
            texts: {
              filter: '',
              filterPlaceholder: 'Поиск по таблице',
              noResults: 'Нет подходящих записей',
              filterBy: '',
              loading: 'Загрузка',
              count: '',
              limit: 'Количество записей на странице:',
              page: 'Страница:'
            },
            rowClassCallback: item => `table-default__row table-default__row--${item.status}`,
          },
        },
        pagination: {
          pages: 1,
          page: 1,
          count: 0,
          prevLink: '',
          nextLink: ''
        },
        table_limit: 20,
        limitSelected: {name: 20},
        limitOptions: [
          {name: 20},
          {name: 40},
          {name: 60},
          {name: 80},
          {name: 100},
        ],
        status: '',
        member_options: [],
        once: false,
        filters: {
          member: null,
          start_date: null,
          end_date: null,
          expired: false
        },
        sidebar_open: false,
        isFiltered: false
      }
    },
    created() {
      this.$store.dispatch('users/GET_DATA')
        .then(response => {
          this.member_options = response.data
        })
    },
    mounted() {
      this.getList(1, this.limitSelected.name)
    },
    methods: {
      getList(page = 1, page_size, status = '', search = '') {
        this.pagination.page = page
        let filters = this.normalizeFilters();
        if (!this.isFiltered) {
          filters = {
            member: '',
            start_date: '',
            end_date: '',
            expired: ''
          };
        }
        this.$store.dispatch('profile/tasks/GET_DATA', { page, page_size, status, search, filters })
          .then(response => {
            this.table.items = response.data.results
            this.pagination.pages = response.data.pages
            this.pagination.count = response.data.count
            this.table.items.forEach((item, i) => {
              item.workers = {
                observer: {},
                executor: {},
                co_executor: {}
              }
              if(item.observer.length) {
                item.workers.observer = item.observer
              }
              if(item.executor.length) {
                item.workers.executor = item.executor
              }
              if(item.co_executor.length) {
                item.workers.co_executor = item.co_executor
              }
            })
          })
      },
      normalizeFilters() {
        const normalizedFilters = {}
        normalizedFilters.member = this.filters.member || ''
        normalizedFilters.start_date = this.filters.start_date || ''
        normalizedFilters.end_date = this.filters.end_date || ''
        normalizedFilters.expired = this.filters.expired || ''
        return normalizedFilters
      },
      updateLimiter(data) {
        this.limitSelected.name = data.name
        this.$refs.tasksListTable.setLimit(data.name)
        this.getList(this.pagination.page, this.limitSelected.name, this.status)
      },
      returnStart() {
        return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
      },
      returnEnd() {
        return this.returnStart() + this.table.items.length - 1
      },
      onClickPagination(page) {
        this.getList(page, this.table_limit)
      },
      onRowClick(row) {
        this.$router.push({ name: 'task', params: { id: row.row.id } })
      },
      onOpenSidebar() {
        this.sidebar_open = true
      },
      applyFilters() {
        this.isFiltered = true;
        this.getList()
        this.sidebar_open = false
      },
      removeFilters() {
        this.filters.member = null
        this.filters.start_date = null
        this.filters.end_date = null
        this.filters.expired = false
        this.isFiltered = false;
        this.getList();
        this.sidebar_open = false
      },
    },

  }
</script>
